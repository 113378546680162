import { useParams, useNavigate } from "react-router";
import { Button, Loading } from "Components";
import { useGetSingleCardById } from "./useSingleCardApi";
import { LuChevronLeft, LuMinus, LuPlus } from "react-icons/lu";
import { useCart } from "Context/CartContext";
import "./style.css";

export const SingleCard = () => {
  let { productId } = useParams();
  const navigate = useNavigate();

  const { data, isLoading } = useGetSingleCardById(productId);

  const {
    addToCart,
    cartItems,
    incrementQuantity,
    decrementQuantity,
    removeFromCart,
  } = useCart();

  const goBack = () => {
    navigate("/");
  };

  const isInCart = (productId) => {
    return cartItems.some((item) => item._id === productId);
  };

  return (
    <div className="flex_c main_container">
      <div
        className="flex_r align_items_center"
        onClick={goBack}
        style={{ cursor: "pointer" }}
      >
        <LuChevronLeft style={{ fontSize: "25px" }} />
        <p className="dashboard_title">Back To Home</p>
      </div>

      {isLoading ? (
        <Loading />
      ) : (
        <>
          <div className="flex_r gap-30 single_card_details_section">
            <div className="single_card_details_img ">
              <img src={data.image} alt="card_image" />
            </div>
            <div className="flex_c single_card_info_details gap-20 ">
              <h1>{data.name}</h1>
              <p>{data.shortDesc}</p>
              <div className="flex_c gap-20 single_card_price">
                <p> $ {data.price}</p>
                <div className="flex_c gap-10 ">
                  {data.stock === 0 ? (
                    <div className="stock_items_left" style={{ color: "red" }}>
                      Out of stock
                    </div>
                  ) : (
                    <div className="stock_items_left">
                      {data.stock} items left
                    </div>
                  )}

                  <div className="flex_r gap-10 cart_stock_quantity">
                    {isInCart(productId) && (
                      <div className="flex_r">
                        <div className="flex_r gap-20 align_items_center cart_quantity_icons">
                          <LuMinus
                            onClick={() => decrementQuantity(productId)}
                            className="cart_icon"
                          />
                          {cartItems.filter((item) => item._id === productId)
                            .length > 0
                            ? cartItems.filter(
                                (item) => item._id === productId
                              )[0].quantity
                            : 1}
                          <LuPlus
                            onClick={() => incrementQuantity(productId)}
                            className="cart_icon"
                          />
                        </div>
                      </div>
                    )}

                    <div>
                      {isInCart(productId) ? (
                        <button
                          className="product_Modal_btn_delete"
                          onClick={() => removeFromCart(productId)}
                        >
                          Remove from cart
                        </button>
                      ) : (
                        <Button
                          name={"Add to cart"}
                          disabled={data.stock === 0}
                          onClick={() =>
                            addToCart({
                              _id: data._id,
                              name: data.name,
                              image: data.image,
                              price: data.price,
                              stock: data.stock,
                            })
                          }
                          style={
                            data.stock === 0
                              ? { background: "grey", cursor: "unset" }
                              : {}
                          }
                        />
                      )}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div className=" flex_c gap-40 single_card_details_section">
            {/* Description Section */}
            <div className="flex_c gap-20">
              <h1 className="main_header">Product Description</h1>
              <p>{data.description}</p>
            </div>
          </div>
        </>
      )}
      {/* Similar Section */}
      {/* <div>
                <div className="flex_c">
                    <h1 className="main_header">Similar Product</h1>
                    <div className="flex_r card_list">
                        <Card
                            data={{ _id: "1", name: "test similar product", price: 100, stock: 20 }}
                        />
                        <Card
                            data={{ _id: "2", name: "test similar product", price: 100, stock: 20 }}
                        />
                        <Card
                            data={{ _id: "3", name: "test similar product", price: 100, stock: 20 }}
                        />
                        <Card
                            data={{ _id: "4", name: "test similar product", price: 100, stock: 20 }}
                        />
                    </div>
                </div>
            </div> */}
    </div>
  );
};
