import React from "react";
import { LuInstagram, LuFacebook } from "react-icons/lu";
// import coffe from "Assets//aboudanLogo.png";
import "./style.css";

export const Footer = () => {
  return (
    <div className="flex_c footer_container">
      <div className="flex_r footer_sub_container">
        <div className="flex_c gap-20 footer_about">
          <div className="flex_r gap-20 footer_title">
            {/* <img
              src={coffe}
              alt={"brand logo"}
              className="background_footer_logo"
            /> */}
            <h1>Sobhi Aboudan</h1>
          </div>
          <p>
            Indulge in luxury and sophistication at our premier destination for
            brand watches. Discover an opulent array of timepieces meticulously
            crafted by prestigious brands known for their heritage, innovation,
            and unparalleled quality. From iconic Swiss craftsmanship to
            avant-garde designs from around the world, our curated collection
            showcases the epitome of elegance and precision. Elevate your style
            and make a statement with every tick of the clock, only at our
            esteemed watch emporium
          </p>
        </div>

        <div className="flex_c gap-20 footer_info">
          <div className="footer_title">
            <h1> More Info</h1>
          </div>
          <div className="flex_c footer_list">
            <p>Nakabet al Atebba street</p>
            <p>Next to Patisserie la Madeleine </p>
            <p>In front of Al Fayssal Restaurant </p>
          </div>
        </div>

        <div className="flex_c gap-20 footer_contact">
          <div className="footer_title">
            <h1>Contact Us</h1>
          </div>
          <ul className="flex_c gap-10 footer_list">
            <li>+961 3 171 113</li>
            <li>Tripoli Lebanon</li>
            <li>North Lebanon</li>
            <div className="flex_r gap-10">
              <a href="https://www.facebook.com/sobhi.aboudan/" target="_blank">
                <LuFacebook className="social_icon" />
              </a>
              <a href="https://www.instagram.com/sobhiaboudan/" target="_blank">
                <LuInstagram className="social_icon" />
              </a>
            </div>
          </ul>
        </div>
      </div>

      <div className="flex_r footer_copy_right">
        <p>Copyright ©Moe Matar All rights reserved '2024'.</p>
      </div>
    </div>
  );
};
