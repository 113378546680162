import React from "react";
import { AllCards } from "Pages";
import { Searchbar } from "Components";
import "./style.css";

export const Home = () => {
  return (
    <div className="flex_c gap-40  main_container">
      <div className="display_none_Home">
        <Searchbar className="login_input navbar_search_form" />
      </div>
      <AllCards />
    </div>
  );
};
