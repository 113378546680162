import { useMutation, useQuery } from "@tanstack/react-query";
import { getOrder, updateOrderStatus } from "./ordersApis";
import { useAuth } from "Context/AuthContext";
import { queryClient } from "App";

const orderKey = "ORDER_KEY";

export const useOrderApis = (query) => {
  const auth = useAuth();
  const token = auth.authorizationToken();

  return useQuery({
    queryKey: [orderKey, query],
    queryFn: () => getOrder(token, query),
    refetchInterval: 5000,
    enabled: true,
    onError: (error) => {
      console.error("An error occurred:", error);
      throw new Error(error);
    },
  });
};

export const useUpdateOrderStatus = () => {
  const auth = useAuth();
  const token = auth.authorizationToken();

  return useMutation({
    mutationFn: ({ orderId, data }) => {
      return updateOrderStatus(token, orderId, data);
    },
    onSuccess: () => {
      queryClient.invalidateQueries(orderKey);
    },
  });
};
