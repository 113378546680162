import React from "react";
import { NavLink } from "react-router-dom";
import { useCart } from "Context/CartContext";
import { Searchbar } from "Components";
import { LuShoppingCart, LuLayoutDashboard, LuLogIn } from "react-icons/lu";
import aboudanLogo from "Assets/aboudanLogo.png";
import { useAuth } from "Context/AuthContext";
import "./style.css";

export const Navbar = () => {
  const auth = useAuth();
  const token = auth.authorizationToken();

  const { cartItems } = useCart();

  return (
    <div className="navbar_section">
      <div className="flex_r navbar_container">
        <div
          style={{
            marginTop: "10px",
          }}
        >
          <img src={aboudanLogo} className="logo_name" />
        </div>
        <div className="search-form">
          <Searchbar className="login_input" />
        </div>
        <div className="flex_r gap-10 align_items_center">
          {token ? (
            <NavLink
              to="/dashboard/products"
              className={({ isActive }) =>
                ["navbar_icon", isActive ? "active" : ""].join(" ")
              }
            >
              <LuLayoutDashboard />
            </NavLink>
          ) : (
            <NavLink
              to="/dashboard/products"
              className={({ isActive }) =>
                ["navbar_icon", isActive ? "active" : ""].join(" ")
              }
            >
              <LuLogIn />
            </NavLink>
          )}
          <NavLink
            to="/cart"
            className={({ isActive }) =>
              ["navbar_icon", isActive ? "active" : ""].join(" ")
            }
          >
            <LuShoppingCart />
            <span className="cart_items_number">{cartItems.length}</span>
          </NavLink>
        </div>
      </div>
    </div>
  );
};
