import React, { useEffect, useRef, useState } from "react";
import { Navigate, useNavigate } from "react-router-dom";
import { Input, Loading, Table } from "Components";
import { Button, Menu, MenuItem, Box, Modal, Typography } from "@mui/material";
import {
  LuChevronDown,
  LuImagePlus,
  LuMoreHorizontal,
  LuPlusCircle,
  LuSearch,
  LuUpload,
  LuX,
} from "react-icons/lu";
import { switchSortedByValue } from "Helpers/switchSortedByValue";
import { switchStatusColor } from "Helpers/switchStatusColor";
import {
  useAddProduct,
  useDeleteProduct,
  useGetProductById,
  useGetProductsAnalytics,
  useGetProductsTable,
  useUpdateProduct,
  useUploadCSV,
} from "./useProductAnalyticsApis";
import "./style.css";
import { handleFileUpload } from "Helpers/uploadImageApi";
import { useAuth } from "Context/AuthContext";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 800,
  bgcolor: "background.paper",
  border: "2px solid #000",
  boxShadow: 24,
  p: 4,
};

export const DashboardProducts = () => {
  const navigate = useNavigate();
  const { authorizationToken, logout } = useAuth();

  const fileInputRef = useRef(null);
  const [formData, setFormData] = useState({});

  const [openModal, setOpenModal] = useState(null);

  const [productMenu, setProductMenu] = useState(null);
  const [selectedProduct, setSelectedProduct] = useState(null);
  const [selectedProductforEdit, setSelectedProductforEdit] = useState(null);

  const [tableCurrentPage, setTableCurrentPage] = useState(1);
  const tableLimit = 20;

  const [sortedByMenu, setSortedByMenu] = useState(false);
  const [sortedBy, setSortedBy] = useState("newest");
  const handleSortedBy = (option) => {
    setSortedBy(option);
    setSortedByMenu(false);
  };

  const [searchKeywords, setSearchKeywords] = useState("");
  const searchInputRef = useRef(null);

  const {
    data: getData,
    status: productsAnalyticsStatus,
    error: productsAnalyticsError,
  } = useGetProductsAnalytics();

  const {
    data: getTableData,
    status: tableDataStatus,
    error: tableDataError,
    refetch: productFetch,
  } = useGetProductsTable({
    limit: tableLimit,
    page: tableCurrentPage,
    keywords: searchKeywords,
    sortBy: sortedBy,
  });

  useEffect(() => {
    productFetch();
  }, [tableCurrentPage, searchKeywords, sortedBy, productFetch]);

  const {
    data: getProductByIdData,
    refetch: oneProductData,
    status: oneProductStatus,
    error: oneProductError,
  } = useGetProductById(selectedProductforEdit);

  useEffect(() => {
    if (selectedProductforEdit) {
      oneProductData();
    }
  }, [selectedProductforEdit, oneProductData]);

  useEffect(() => {
    if (getProductByIdData) {
      setFormData(getProductByIdData);
    }
  }, [getProductByIdData]);

  const { mutateAsync: updateProduct, status: updateProductStatus } =
    useUpdateProduct();

  const { mutateAsync: deleteProduct, status: deleteProductStatus } =
    useDeleteProduct();

  const { mutateAsync: createNewProduct, status: createProductStatus } =
    useAddProduct();

  const { mutateAsync: uploadCSV, status: uploadCSVStatus } = useUploadCSV();

  const handleChange = (e) => {
    const fieldName = e?.target.name;
    let value = e?.target.value;
    if (fieldName === "stock" || fieldName === "price") {
      value = Number(value);
    }
    setFormData((prevData) => ({
      ...prevData,
      [fieldName]: value,
    }));
  };

  const handleChangeImage = async (e) => {
    try {
      let response = await handleFileUpload(
        authorizationToken(),
        e.target.files[0]
      );
      setFormData((prevData) => ({
        ...prevData,
        image: response.data.image,
      }));
    } catch (err) {
      console.err(err);
    }
  };

  const handleDelete = async (id) => {
    try {
      const response = await deleteProduct({ productId: id });
      if (!response.success) {
        alert(response.message);
      }
      return response;
    } catch (error) {
      console.error(error);
      return null;
    }
  };

  const handleSubmitSearch = (e) => {
    e.preventDefault();
    setSearchKeywords(searchInputRef.current.value);
  };

  const updateStatusById = async (id, newStatus) => {
    try {
      const response = await updateProduct({
        productId: id,
        data: { status: newStatus },
      });
      if (!response.success) {
        alert(response.message);
      }
      return response;
    } catch (error) {
      console.error(error);
      return null;
    }
  };

  const updateProductById = async () => {
    try {
      const response = await updateProduct({
        productId: selectedProductforEdit,
        data: formData,
      });
      if (!response.success) {
        alert(response.message);
      }
      setOpenModal(null);
      setSelectedProduct(null);
      setSelectedProductforEdit(null);
      setFormData({});
      return response;
    } catch (error) {
      console.error(error);
      return null;
    }
  };

  const handleFileChange = async (event) => {
    const file = event.target.files[0];
    if (file) {
      const fileType = file.type;
      if (fileType !== "text/csv") {
        alert("Please select a CSV file");
        return;
      }
      const formData = new FormData();
      formData.append("file", file);
      try {
        const response = await uploadCSV({ formData });
        if (!response.success) {
          alert(response.message);
        }
        console.log("File uploaded successfully", response);
        return response;
      } catch (error) {
        console.error(error);
        return null;
      }
    }
  };

  const createProduct = async () => {
    try {
      const response = await createNewProduct(formData);
      if (!response.success) {
        alert(response.message);
        return response;
      }
      return response;
    } catch (error) {
      console.error(error);
      return null;
    }
  };

  if (tableDataStatus === "pending" || productsAnalyticsStatus === "pending") {
    return <Loading />;
  }

  if (oneProductStatus === "error") {
    if (
      oneProductError?.message ===
        "Your sessions token has expired !! Login again" ||
      oneProductError?.message === "Unauthorized to access" ||
      oneProductError?.message === "Invalid Token" ||
      oneProductError?.message === "The token owner no longer exist"
    ) {
      alert(oneProductError?.message);
      logout();
      return <Navigate to={"/login"} />;
    }
    return <div>{oneProductError?.message}</div>;
  }

  if (productsAnalyticsStatus === "error") {
    if (
      productsAnalyticsError?.message ===
        "Your sessions token has expired !! Login again" ||
      productsAnalyticsError?.message === "Unauthorized to access" ||
      productsAnalyticsError?.message === "Invalid Token" ||
      productsAnalyticsError?.message === "The token owner no longer exist"
    ) {
      alert(productsAnalyticsError?.message);
      logout();
      return <Navigate to={"/login"} />;
    }
    return <div>{productsAnalyticsError?.message}</div>;
  }

  if (tableDataStatus === "error") {
    if (
      tableDataError?.message ===
        "Your sessions token has expired !! Login again" ||
      tableDataError?.message === "Unauthorized to access" ||
      tableDataError?.message === "Invalid Token" ||
      tableDataError?.message === "The token owner no longer exist"
    ) {
      alert(tableDataError?.message);
      logout();
      return <Navigate to={"/login"} />;
    }
    return <div>{tableDataError?.message}</div>;
  }
  if (tableDataStatus === "success" || productsAnalyticsStatus === "success") {
    return (
      <div className="flex_c gap-30">
        <div className="flex_r space_between">
          <h1 className="dashboard_title">Product</h1>
          <div className="flex_r gap-10">
            <input
              id="csvFile"
              ref={fileInputRef}
              type="file"
              accept=".csv"
              hidden
              onChange={handleFileChange}
            />
            <Button
              startIcon={<LuUpload />}
              onClick={() => fileInputRef.current.click()}
            >
              Upload CSV
            </Button>
            <Button
              variant="outlined"
              className="outlined-btn"
              startIcon={<LuPlusCircle />}
              onClick={() => {
                setOpenModal("add");
                setFormData({});
              }}
            >
              Add Product
            </Button>
          </div>
          <Modal
            open={openModal === "add"}
            onClose={() => {
              setOpenModal(null);
              setFormData({});
            }}
            aria-labelledby="modal-modal-title"
            aria-describedby="modal-modal-description"
          >
            <Box sx={style} className="modal-content-wrapper">
              <Typography id="modal-modal-title" variant="h6" component="h2">
                <div
                  className="flex_r"
                  style={{ justifyContent: "space-between" }}
                >
                  Add New Product
                  <LuX
                    onClick={() => {
                      setOpenModal(null);
                      setFormData({});
                    }}
                  />
                </div>
              </Typography>
              <Typography
                id="modal-modal-description"
                sx={{
                  mt: 2,
                  display: "flex",
                  flexDirection: "column",
                  gap: "20px",
                }}
              >
                <Input
                  type="text"
                  label={"Product Name*"}
                  id="productName"
                  placeholder="Product Name"
                  required
                  className="checkout_input"
                  name="name"
                  onChange={handleChange}
                />
                <div className="flex_r gap-10 product_modal_group">
                  <div className="flex_c product_modal_group_item">
                    <Input
                      label={"Price*"}
                      type="number"
                      id="price"
                      placeholder="Price"
                      min={0}
                      step={0.01}
                      required
                      className="checkout_input"
                      name="price"
                      onChange={handleChange}
                    />
                    <p className="add_newproduct_currencay_modal"> $</p>
                  </div>
                  <div className="product_modal_group_item">
                    <Input
                      type="number"
                      label={"Stock*"}
                      id="stock"
                      placeholder="Stock"
                      required
                      min={0}
                      className="checkout_input"
                      name="stock"
                      onChange={handleChange}
                    />
                  </div>
                  <div className="product_modal_group_item">
                    <Input
                      type="text"
                      label={"SKU*"}
                      id="sku"
                      placeholder="SKU"
                      required
                      className="checkout_input"
                      name="sku"
                      onChange={handleChange}
                    />
                  </div>
                </div>

                <Input
                  type="text"
                  label={"Short Description*"}
                  id="productName"
                  placeholder="Short Description"
                  required
                  className="checkout_input"
                  name="shortDesc"
                  onChange={handleChange}
                />
                <div className="flex_c gap-10">
                  <label className="label">Product Description*</label>
                  <textarea
                    className="custom_textarea checkout_input"
                    placeholder="Product Description"
                    name="description"
                    required
                    onChange={handleChange}
                  ></textarea>
                </div>
                <Input
                  type="text"
                  label={"Keywords*"}
                  id="price"
                  placeholder="keywords"
                  required
                  className="checkout_input"
                  name="keywords"
                  onChange={handleChange}
                />
                <p className="input_text">
                  Just keep a space between the words.
                </p>
                <label className="label_inputs">
                  <span className="label">Product Image*</span>
                  <div className="flex_r space_between image_input align_items_center">
                    <div className="flex_r gap-10 align_items_center">
                      {formData.image ? (
                        <img src={formData.image} alt={formData.name} />
                      ) : (
                        <div className="image_placeholder">
                          <LuImagePlus />
                        </div>
                      )}
                      <p>Upload Image</p>
                    </div>
                    <input
                      id="productImage"
                      type="file"
                      hidden
                      onChange={handleChangeImage}
                    />
                    <LuUpload />
                  </div>
                </label>
                <div className="flex_r gap-20 product_Modal_btn">
                  <button
                    className="product_Modal_btn_cancel"
                    onClick={() => {
                      setOpenModal(null);
                      setFormData({});
                    }}
                  >
                    Cancel
                  </button>
                  <button
                    className="product_Modal_btn_save"
                    onClick={() => {
                      createProduct();
                      setOpenModal(null);
                    }}
                  >
                    Save Product
                  </button>
                </div>
              </Typography>
            </Box>
          </Modal>
        </div>

        <div className="flex_r flex_wrap">
          <div className="flex_c dashboard_analytics">
            <h1>Total Products</h1>
            <p>{getData.totalProducts || "0"}</p>
          </div>
          <div className="flex_c dashboard_analytics">
            <h1>Out of stock</h1>
            <p>{getData.outOfStock || "0"}</p>
          </div>
          <div className="flex_c dashboard_analytics">
            <h1>Total Items </h1>
            <p>{getData.totalSales || "0"}</p>
          </div>
          <div className="flex_c dashboard_analytics">
            <h1>Sales </h1>
            <p style={{ color: "rgb(44, 218, 83)" }}>
              ${getData.amountSales || "0"}
            </p>
          </div>
        </div>

        <div className="flex_c gap-20">
          <div className="flex_r align_items_center space_between">
            <div className="flex_r gap-20 align_items_center">
              <label htmlFor="select-option" className="sortby_label">
                Sort By :
              </label>
              <Button
                id="products-sort-button"
                endIcon={<LuChevronDown />}
                aria-controls={"products-sort-menu"}
                aria-haspopup="true"
                onClick={(e) => setSortedByMenu(true)}
                variant="outlined"
                className="outlined-btn"
              >
                {switchSortedByValue(sortedBy)}
              </Button>
              <Menu
                id="products-sort-menu"
                anchorEl={document.getElementById("products-sort-button")}
                open={sortedByMenu}
                onClose={() => setSortedByMenu(false)}
                MenuListProps={{
                  "aria-labelledby": "products-sort-button",
                }}
              >
                <MenuItem onClick={() => handleSortedBy("newest")}>
                  Newest Item
                </MenuItem>
                <MenuItem onClick={() => handleSortedBy("mostPopular")}>
                  Most Popular
                </MenuItem>
                <MenuItem onClick={() => handleSortedBy("priceHTL")}>
                  Price (hight to low)
                </MenuItem>
                <MenuItem onClick={() => handleSortedBy("priceLTH")}>
                  Price (low to high)
                </MenuItem>
                <MenuItem onClick={() => handleSortedBy("alphabetical")}>
                  Alphabetical
                </MenuItem>
              </Menu>
            </div>
            <form
              className="dashboard_product_searchbar_container"
              onSubmit={handleSubmitSearch}
            >
              <Input
                ref={searchInputRef}
                className="outlined_input"
                type={"text"}
                placeholder="Search"
                // value={searchKeywords}
                name={"keywords"}
                // onChange={handleChangeSearch}
                startIcon={<LuSearch />}
              />
            </form>
          </div>
          <div className="dashboard_table_container">
            <Table
              heading={[
                "PRODUCT DETAILS",
                "PRICE",
                "SALES",
                "STOCK",
                "STATUS",
                "",
              ]}
              currentPage={getTableData?.currentPage}
              totalPages={getTableData?.totalPages}
              onSelectPage={(page) => setTableCurrentPage(page)}
              onNextPage={() => setTableCurrentPage(tableCurrentPage + 1)}
              onPreviousPage={() => setTableCurrentPage(tableCurrentPage - 1)}
            >
              {getTableData?.items.map((product) => (
                <tr key={product._id}>
                  <td>
                    <div className="flex_r gap-20 align_items_center">
                      <img
                        src={product.image}
                        className="dashboard_table_row_img"
                        alt={product.name}
                      />
                      {product.name}
                    </div>
                  </td>
                  <td>${product.price}</td>
                  <td>{product.sales.items}</td>
                  <td>{product.stock}</td>
                  <td>
                    <Button
                      id={`product-status-button-${product._id}`}
                      aria-controls={
                        selectedProduct === product._id
                          ? `product-status-menu-${product._id}`
                          : undefined
                      }
                      aria-haspopup="true"
                      aria-expanded={
                        selectedProduct === product._id ? "true" : undefined
                      }
                      onClick={(e) => {
                        setSelectedProduct(product._id);
                        setProductMenu("status");
                      }}
                      endIcon={<LuChevronDown />}
                      style={{
                        fontSize: "14px",
                        color: switchStatusColor(product.status),
                        border: `2px solid ${switchStatusColor(
                          product.status
                        )}`,
                      }}
                    >
                      {product.status}
                    </Button>
                    <Menu
                      id={`product-status-menu-${product._id}`}
                      anchorEl={document.getElementById(
                        `product-status-button-${product._id}`
                      )}
                      open={
                        selectedProduct === product._id &&
                        productMenu === "status"
                      }
                      onClose={() => setSelectedProduct(null)}
                      MenuListProps={{
                        "aria-labelledby": `product-status-button-${product._id}`,
                      }}
                    >
                      <MenuItem
                        onClick={() => {
                          updateStatusById(product._id, "published");
                          setSelectedProduct(null);
                        }}
                        style={{ color: "green" }}
                      >
                        Published
                      </MenuItem>
                      <MenuItem
                        onClick={() => {
                          updateStatusById(product._id, "draft");
                          setSelectedProduct(null);
                        }}
                        style={{ color: "red" }}
                      >
                        Draft
                      </MenuItem>
                    </Menu>
                  </td>
                  <td>
                    <Button
                      id={`product-action-button-${product._id}`}
                      aria-controls={
                        selectedProduct === product._id
                          ? `product-action-menu-${product._id}`
                          : undefined
                      }
                      aria-haspopup="true"
                      aria-expanded={
                        selectedProduct === product._id ? "true" : undefined
                      }
                      onClick={(e) => {
                        setSelectedProduct(product._id);
                        setProductMenu("action");
                      }}
                      style={{
                        fontSize: "25px",
                        color: "#3c4242",
                      }}
                    >
                      <LuMoreHorizontal />
                    </Button>
                    <Menu
                      id={`product-action-menu-${product._id}`}
                      anchorEl={document.getElementById(
                        `product-action-button-${product._id}`
                      )}
                      open={
                        selectedProduct === product._id &&
                        productMenu === "action"
                      }
                      onClose={() => setSelectedProduct(null)}
                      MenuListProps={{
                        "aria-labelledby": `product-action-button-${product._id}`,
                      }}
                    >
                      <MenuItem
                        onClick={() =>
                          navigate(`/dashboard/products/${product._id}`)
                        }
                      >
                        View Details
                      </MenuItem>
                      <MenuItem
                        onClick={() => {
                          setSelectedProductforEdit(product._id);
                          setOpenModal("edit");
                          setProductMenu(null);
                        }}
                      >
                        Edit
                      </MenuItem>
                      <MenuItem
                        onClick={() => {
                          handleDelete(product._id);
                          setProductMenu(null);
                        }}
                        style={{ color: "red" }}
                      >
                        Delete
                      </MenuItem>
                    </Menu>
                  </td>
                </tr>
              ))}
            </Table>
            {oneProductStatus === "pending" && selectedProductforEdit ? (
              <Loading />
            ) : (
              <Modal
                open={openModal === "edit"}
                onClose={() => {
                  setOpenModal(null);
                  setSelectedProduct(null);
                  setFormData({});
                }}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
              >
                <Box sx={style} className="modal-content-wrapper">
                  <Typography
                    id="modal-modal-title"
                    variant="h6"
                    component="h2"
                  >
                    <div
                      className="flex_r"
                      style={{ justifyContent: "space-between" }}
                    >
                      Edit Product
                      <LuX
                        onClick={() => {
                          setOpenModal(null);
                          setSelectedProduct(null);
                          setFormData({});
                        }}
                      />
                    </div>
                  </Typography>
                  <Typography
                    id="modal-modal-description"
                    sx={{
                      mt: 2,
                      display: "flex",
                      flexDirection: "column",
                      gap: "20px",
                    }}
                  >
                    <Input
                      type="text"
                      label={"Product Name*"}
                      id="productName"
                      placeholder="Product Name"
                      required
                      value={formData.name}
                      className="checkout_input"
                      name="name"
                      onChange={handleChange}
                    />
                    <div className="flex_r gap-10 product_modal_group">
                      <div className="flex_c product_modal_group_item">
                        <Input
                          label={"Price*"}
                          type="number"
                          id="price"
                          placeholder="Price"
                          min={0}
                          step={0.01}
                          required
                          value={formData.price}
                          className="checkout_input"
                          name="price"
                          onChange={handleChange}
                        />
                        <p className="add_newproduct_currencay_modal"> $</p>
                      </div>
                      <div className="product_modal_group_item">
                        <Input
                          type="number"
                          label={"Stock*"}
                          id="stock"
                          placeholder="Stock"
                          required
                          value={formData.stock}
                          min={0}
                          className="checkout_input"
                          name="stock"
                          onChange={handleChange}
                        />
                      </div>
                      <div className="product_modal_group_item">
                        <Input
                          type="text"
                          label={"SKU*"}
                          className="checkout_input"
                          id="sku"
                          placeholder="SKU"
                          required
                          value={formData.sku}
                          name="sku"
                          onChange={handleChange}
                        />
                      </div>
                    </div>

                    <Input
                      type="text"
                      label={"Short Description"}
                      id="productName"
                      placeholder="Short Description"
                      className="checkout_input"
                      name="shortDesc"
                      onChange={handleChange}
                      value={formData.shortDesc}
                    />
                    <div className="flex_c gap-10">
                      <label className="label">Product Description</label>
                      <textarea
                        className="custom_textarea checkout_input"
                        placeholder="Product Description"
                        value={formData.description}
                        name="description"
                        required
                        onChange={handleChange}
                      ></textarea>
                    </div>
                    <Input
                      type="text"
                      label={"Keywords"}
                      className="checkout_input"
                      placeholder="keywords"
                      required
                      name="keywords"
                      onChange={handleChange}
                      value={formData.keywords}
                    />
                    <p className="input_text">
                      Just keep a space between the words.
                    </p>
                    <label className="label_inputs">
                      <span className="label">Product Image*</span>
                      <div className="flex_r space_between image_input align_items_center">
                        <div className="flex_r gap-10 align_items_center">
                          {formData.image ? (
                            <img src={formData.image} alt={formData.name} />
                          ) : (
                            <div className="image_placeholder">
                              <LuImagePlus />
                            </div>
                          )}
                          <p>Upload Image</p>
                        </div>
                        <input
                          id="productImage"
                          type="file"
                          hidden
                          onChange={handleChangeImage}
                        />
                        <LuUpload />
                      </div>
                    </label>
                    <div className="flex_r gap-20 product_Modal_btn">
                      <button
                        className="product_Modal_btn_cancel"
                        onClick={() => {
                          setOpenModal(null);
                          setSelectedProduct(null);
                          setFormData({});
                        }}
                      >
                        Cancel
                      </button>
                      <button
                        className="product_Modal_btn_save"
                        onClick={updateProductById}
                      >
                        Save Product
                      </button>
                    </div>
                  </Typography>
                </Box>
              </Modal>
            )}
            {updateProductStatus === "pending" && !selectedProductforEdit && (
              <Loading />
            )}
            {(deleteProductStatus === "pending" ||
              createProductStatus === "pending" ||
              uploadCSVStatus === "pending") && <Loading />}
          </div>
        </div>
      </div>
    );
  }
};
