import React, { useState } from "react";
import { Input } from "Components";
import { useSearchContext } from "Context/SearchContext";
import { useNavigate } from "react-router-dom";
import "./style.css";

export const Searchbar = ({ className }) => {
  const navigate = useNavigate();
  const [searchKeyword, setSearchKeyword] = useState("");
  const { updateSearchTerm } = useSearchContext();

  const handleChange = (e) => {
    setSearchKeyword(e.target.value);
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    navigate("/");
    updateSearchTerm(searchKeyword);
  };

  const handleKeyPress = (event) => {
    if (event.key === "Enter") {
      console.log("Enter key pressed!");
    }
  };
  return (
    <form onSubmit={handleSubmit} className="flex_r w-100 searchbar_container">
      <Input
        type="text"
        value={searchKeyword}
        onChange={handleChange}
        onKeyDown={handleKeyPress}
        className={className}
        placeholder={"Search"}
        style={{
          gap: "0",
        }}
      />
      <div className="button_search_section">
        <button type="submit" className="searchbar_button">
          Search
        </button>
      </div>
    </form>
  );
};
